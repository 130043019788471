import React from "react";
import styles from "./index.module.css";
import { getUrlParams } from "../Helpers";

const chooseContent = () => {
  if (typeof window !== "undefined") {
    let message = null;
    const params = getUrlParams(window.location.search);
    switch (params.formSubmit) {
      case "newsletter":
        message =
          "Thank you for subscribing to EXPERIENCE! You will receive an email welcoming you to this subscription.";
        break;
      case "download":
        message =
          "Thank you! You will receive an email with a link to the download.";
        break;
      default:
        message =
          "Thank you for your information. A Hamilton representative will be contacting you soon.";
    }
    return message;
  }
  return null;
};

export default function FormSubmit() {
  return (
    <div className={styles.container}>
      <p className={styles.heading}>Form Successfully Submitted</p>
      <p className={styles.message}>{chooseContent()}</p>
    </div>
  );
}
