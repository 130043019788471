import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import styles from "./index.module.css";
import Moment from "react-moment";

export default function BlogCardGrid() {
  const data = useStaticQuery(graphql`
    {
      craft {
        entries(type: "blog") {
          ... on Craft_blog_blog_Entry {
            id
            postDate
            blogCardColor
            blogShortText
            title
            uri
            author {
              name
              photo {
                ... on Craft_uploads_Asset {
                  id
                  url: url(width: 55, position: "centerCenter")
                  url_2x: url(width: 110, position: "centerCenter")
                  altText
                }
              }
            }
          }
        }
      }
    }
  `);

  const blogEntries = data.craft.entries;
  return (
    <ul className="list-reset grid grid3col">
      {blogEntries.map(entry => (
        <li key={entry.id}>
          <article
            className={`${styles.BlogCard} ${
              styles["BlogCard" + entry.blogCardColor]
            }`}
            aria-label="team member"
          >
            <Link to={`/${entry.uri}/`}>
              <header>
                {entry.dateCreated}
                <Moment format="MMM DD, YYYY">{entry.postDate}</Moment>
              </header>

              <div className={styles.BlogCard__body}>
                <h3
                  dangerouslySetInnerHTML={{ __html: entry.title }}
                  className={styles.BlogCard__title}
                ></h3>
                <p>{entry.blogShortText}</p>
              </div>

              <footer className={styles.BlogCard__footer}>
                <div className={styles.BlogCard__footerAuthor}>
                  {entry.author.photo &&
                    <img
                      src={entry.author.photo.url}
                      srcSet={` ${entry.author.photo.url + ' 1x'}, ${entry.author.photo.url_2x + ' 2x'} `}
                      alt={entry.author.photo.altText}
                    />
                  }
                  <b className={styles.BlogCard__footerAuthorName}>
                    {entry.author.name}
                  </b>
                </div>
              </footer>
            </Link>
          </article>
        </li>
      ))}
    </ul>
  );
}

export const blogCardGridData = graphql`
  fragment blogCardGridData on Craft_modules_blogCardGrid_BlockType {
    id
    typeHandle
  }
`;
