import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Plyr from "plyr";
import "plyr/dist/plyr.css";
import styles from "./index.module.css";
import { graphql } from "gatsby";

export default function VideoPlayer(props) {
  // Destructure Props
  const { type, src, thumbnail, ratio, options } = props;

  // Initialize Plyr & Options
  const video = React.createRef();

  function initPlyr() {
    window.video = new Plyr(video.current, {
      ...options,
      ratio,
      storage: { enabled: false },
      youtube: { showinfo: 0, modestbranding: 1 }
    });
  }

  useEffect(() => initPlyr());

  return (
    <>
      {type === "mp4" && (
        <video
          playsInline
          className={styles.video}
          poster={thumbnail}
          ref={video}
        >
          <source src={src} type="video/mp4" />
        </video>
      )}
      {type === "embed" && (
        <div className="plyr__video-embed" ref={video}>
          <iframe title="Video embed" src={src} frameBorder="0"></iframe>
        </div>
      )}
    </>
  );
}

VideoPlayer.propTypes = {
  ratio: PropTypes.oneOf(["16:9", "4:3"]),
  src: PropTypes.string,
  thumbnail: PropTypes.string,
  type: PropTypes.oneOf(["mp4", "embed"]),
  options: PropTypes.object
};

VideoPlayer.defaultProps = {
  ratio: "16:9",
  src: "/videos/video.mp4",
  thumbnail: "",
  type: "mp4",
  options: {}
};

export const videoData = graphql`
  fragment videoData on Craft_modules_video_BlockType {
    id
    typeHandle
    thumbnail {
      url
    }
    video {
      url
    }
    showDarkVariant
  }
`;
