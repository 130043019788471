import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import Envelope from "../../images/envelope.svg";
import styles from "./index.module.css";

function Footer({ heroVariation }) {
  const data = useStaticQuery(graphql`
    {
      craft {
        globalSets(handle: "footer")  {
          ... on Craft_footer_GlobalSet {
            id
            name
            socialLinks {
              ... on Craft_socialLinks_socialLinks_BlockType {
                id
                linkUrl
                socialIcon {
                  ... on Craft_uploads_Asset {
                    id
                    altText
                    url: url(width: 40, position: "centerCenter")
                    url_2x: url(width: 80, position: "centerCenter")
                  }
                }
              }
            }
            newsLetterSignupLink
          }
        }
        nodes(navHandle: "footerNav") {
          id
          title
          url
          newWindow
        }
      }
    }
  `);
  const socialLinksData = data.craft.globalSets[0].socialLinks;
  const newsLetterSignupLink = data.craft.globalSets[0].newsLetterSignupLink;
  const footerNav = data.craft.nodes;
  const footerModifier = heroVariation !== "Default" ? "Sticky" : heroVariation;
  return (
    <footer
      className={`${styles.Footer} ${styles[`Footer` + footerModifier]}`}
      role="contentinfo"
    >
      <small className={styles.Footer__copyright}>
        &copy; {new Date().getFullYear()} Hamilton Exhibits, LLC
      </small>
      <nav>
        {footerNav.map((navLink, key) => {
          return (
            <a
              dangerouslySetInnerHTML={{ __html: navLink.title }}
              key={key}
              href={navLink.url}
              className={styles.Footer__links}
              target={navLink.newWindow == 1 ? '_blank' : ''}
            ></a>
          );
        })}

        {socialLinksData.map((socialLink, key) => {
          return (
            <a
              key={key}
              href={socialLink.linkUrl}
              className={styles.Footer__icons}
            >
              <img
                src={socialLink.socialIcon[0].url}
                srcSet={` ${socialLink.socialIcon[0].url + ' 1x'}, ${socialLink.socialIcon[0].url_2x + ' 2x'} `}
                alt={socialLink.socialIcon[0].altText}
              />
            </a>
          );
        })}

        <a href={newsLetterSignupLink} className={styles.Footer__links}>
          <Envelope />
          <span>Sign up for our Newsletter</span>
        </a>
      </nav>
    </footer>
  );
}

Footer.defaultProps = {
  heroVariation: "Default"
};

Footer.propTypes = {
  heroVariation: PropTypes.string
};

export default Footer;
