import React from "react";
// import { graphql } from "gatsby";
import Flickity from "react-flickity-component"; // https://www.npmjs.com/package/react-flickity-component
import "flickity/css/flickity.css";
import styles from "./index.module.css";

export default function ImageSlider({ images }) {
  const flickityOptions = {
    adaptiveHeight: true,
    wrapAround: true,
    imagesLoaded: true,
    lazyLoad: 1,
    prevNextButtons: true,
    pageDots: false,
    initialIndex: 1,
    arrowShape:
      "M24.3158143,5.28724342 C27.3099972,2.32999919 32.1081528,2.2403862 35.2116677,5.01840289 L35.4975496,5.28724342 L75.6841862,44.9781052 C78.6783691,47.9353494 78.769103,52.6743111 75.9563848,55.7395385 L75.6841862,56.0218951 L35.4975496,95.7127568 C32.4097991,98.7624144 27.4035649,98.7624144 24.3158143,95.7127568 C21.3216299,92.7555126 21.2308975,88.0165509 24.0436158,84.9513235 L24.3158143,84.6689669 L58.9107254,50.5 L24.3158143,16.3310333 C21.3216299,13.3737891 21.2308975,8.63482744 24.0436158,5.5696 L24.3158143,5.28724342 Z"
  };

  return (
    <section className={styles.ImageSlider}>
      <Flickity
        className={"carousel"}
        elementType={"div"}
        options={flickityOptions}
        disableImagesLoaded={false}
      >
        {images.map((image, index) => (
          <figure
            key={index}
            className={`${styles[`ImageSliderCarouselCell`]}`}
          >
            <img data-flickity-lazyload={image.crop} data-flickity-lazyload-srcset={` ${image.crop + ' 1x'}, ${image.crop_2x + ' 2x'} `} alt={image.altText} />
          </figure>
        ))}
      </Flickity>
    </section>
  );
}

export const imageSliderData = graphql`
  fragment imageSliderData on Craft_modules_imageSlider_BlockType {
    typeHandle
    id
    images {
      ... on Craft_uploads_Asset {
        id
        url
        crop: url(width: 1000, height: 700, position: "centerCenter")
        crop_2x: url(width: 2000, height: 1400, position: "centerCenter")
        altText
      }
    }
    showDarkVariant
  }
`;
