import React from "react";
import { graphql } from "gatsby";
import styles from "./index.module.css";
import PropTypes from "prop-types";

export default function LogoGrid({ images }) {
  return (
    <section
      className={`${styles.LogoGrid} ${styles.grid} container container--1132px`}
    >
      {images.map(image => (
        <img
          className={styles.image}
          key={image.id}
          src={image.thumb}
          srcSet={` ${image.thumb + ' 1x'}, ${image.thumb_2x + ' 2x'} `}
          alt={image.altText}
        />
      ))}
    </section>
  );
}

LogoGrid.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      thumb: PropTypes.string,
      thumb_2x: PropTypes.string,
      altText: PropTypes.string
    })
  )
};

LogoGrid.defaultProps = {
  images: Array.from(new Array(12), (item, index) => ({
    id: `${index + 1}`,
    thumb: `https://source.unsplash.com/random/300x300?${index}`,
    thumb_2x: `https://source.unsplash.com/random/600x600?${index}`,
    altText: "Default Alt Text"
  }))
};

export const logoGridData = graphql`
  fragment logoGridData on Craft_modules_logoGrid_BlockType {
    id
    typeHandle
    images {
      ... on Craft_uploads_Asset {
        id
        thumb: url( height: 300 width: 300 position: "centerCenter" mode: "fit" )
        thumb_2x: url( height: 600 width: 600 position: "centerCenter" mode: "fit" )
        altText
      }
    }
  }
`;
