import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import TeamMemberCard from "./TeamMemberCard";

export default function TeamMemberGrid() {
  const data = useStaticQuery(graphql`
    {
      craft {
        entries(type: "teamMembers") {
          ... on Craft_teamMembers_teamMembers_Entry {
            id
            title
            teamMemberBio
            teamMemberImage {
              ... on Craft_uploads_Asset {
                id
                url
                crop: url(height: 388, width: 479, position: "centerCenter")
                retina: url(height: 776, width: 958, position: "centerCenter")
                altText
              }
            }
          }
        }
      }
    }
  `);

  const teamMembersEntries = data.craft.entries;

  return (
    <ul className="list-reset grid grid3col">
      {teamMembersEntries.map((teamMember, index) => (
        <TeamMemberCard
          key={index}
          title={teamMember.title}
          image={teamMember.teamMemberImage}
          bio={teamMember.teamMemberBio}
        />
      ))}
    </ul>
  );
}

export const teamMemberGridData = graphql`
  fragment teamMemberGridData on Craft_modules_teamMemberGrid_BlockType {
    id
    typeHandle
  }
`;
