import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import Nav from "../Nav";
import Footer from "../Footer";
import "../../styles/_app.css";
import Modal from "../Modal";
import FormSubmit from "../FormSubmit";
import { getUrlParams } from "../Helpers";


function Layout({ children, heroVariation, logoColor }) {
  const data = useStaticQuery(graphql`
    {
      craft {
        globalSets(handle: "footer") {
          ... on Craft_footer_GlobalSet {
            id
            name
            socialLinks {
              ... on Craft_socialLinks_socialLinks_BlockType {
                id
                linkUrl
                socialIcon {
                  ... on Craft_uploads_Asset {
                    id
                    altText
                    url: url(width: 40, position: "centerCenter")
                    url_2x: url(width: 80, position: "centerCenter")
                  }
                }
              }
            }
            newsLetterSignupLink
          }
        }
        headerNav: nodes(navHandle: "headerNav", level: 1) {
          ... on Craft_headerNav_Node {
            id
            title
            children {
              url
              title
              newWindow
              slug
            }
            element {
              uri
            }
          }
        }
        footerNav: nodes(navHandle: "footerNav") {
          ... on Craft_footerNav_Node {
            id
            title
            url
          }
        }
      }
    }
  `);

  const socialLinksData = data.craft.globalSets[0].socialLinks;
  const newsLetterSignupLink = data.craft.globalSets[0].newsLetterSignupLink;
  const footerNav = data.craft.footerNav;

  // Globally Display Form Submit Success if this param is present!
  const formSubmitted = () => {
    if (typeof window !== "undefined") {
      const params = getUrlParams(window.location.search);
      return params.formSubmit !== undefined;
    }
  };

  return (
    <>
      <Nav
        logoColor={logoColor}
        navLinks={data.craft.headerNav}
        footerNav={footerNav}
        socialLinksData={socialLinksData}
        newsLetterSignupLink={newsLetterSignupLink}
      />
      <Modal showModal={formSubmitted()} contentNode={<FormSubmit />} />
      <main>{children}</main>
      <Footer heroVariation={heroVariation} />
    </>
  );
}

Layout.defaultProps = {
  heroVariation: "Default"
};

Layout.propTypes = {
  heroVariation: PropTypes.string
};

export default Layout;
