import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Logo from "../../images/main-logo.svg";
import LogoDark from "../../images/main-logo-dark.svg";
import Envelope from "../../images/envelope.svg";
import Hamburger from "../../images/hamburger.svg";
import styles from "./index.module.css";
import useViewportSizes from "use-viewport-sizes";
import { checkCurrentPage, navAndRemoveActive } from "../Helpers";
import NavLink from "./NavLink";

export default function Nav({
  navLinks,
  socialLinksData,
  newsLetterSignupLink,
  footerNav,
  logoColor
}) {
  const [activeClass, setActiveClass] = useState(false);
  const [navExpanded, setNavExpanded] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(null);
  const [expandedKey, setExpandedKey] = useState(false);
  const [vpWidth] = useViewportSizes();

  const logo = React.createRef();
  const header = React.createRef();

  const keyExapanded = key => {
    setExpandedKey(key);
  };

  function chooseLogoColor() {
    if (scrollPosition) {
      return <Logo />;
    }
    if (logoColor === "light") {
      return <Logo />;
    }
    if (logoColor !== "light") {
      return <LogoDark />;
    }
  }

  useEffect(() => {
    if (vpWidth >= 960) {
      window.addEventListener("scroll", function(e) {
        const nav = document.querySelector("[data-nav]");
        setScrollPosition(window.scrollY > nav.offsetTop);
      });
    } else {
      setScrollPosition(false);
    }
  }, [vpWidth]);

  const handleClick = () => {
    const root = document.querySelector("html");

    setActiveClass(!activeClass);
    setNavExpanded(!navExpanded);

    if (!activeClass) {
      root.classList.add("active");
    } else {
      root.classList.remove("active");
    }
  };

  return (
    <header
      className={`${styles.Nav} ${
        scrollPosition ? styles.Nav__isScrolled : ""
      }`}
      role="banner"
      ref={header}
    >
      <div className={styles.Nav__logo} ref={logo}>
        <Link to="/">{chooseLogoColor()}</Link>
      </div>

      <nav id="navigation" role="navigation" data-nav>
        <button
          id="nav-button"
          onClick={handleClick}
          className={`${styles.Nav__button} ${
            activeClass ? styles["Nav__buttonIsActive"] : ""
          }`}
          type="button"
          aria-label="Menu"
          aria-controls="Header__nav"
          aria-expanded={navExpanded}
        >
          <span>
            <Hamburger /> MENU
          </span>
        </button>

        <div
          className={`${styles.Nav__links} ${
            activeClass ? styles["Nav__linksIsActive"] : ""
          }`}
        >
          <ul className={`${styles.Nav__menuItems} ${"list-reset"}`}>
            {navLinks.map((link, index) => (
              <NavLink
                key={index}
                title={link.title}
                keyExapanded={keyExapanded}
                expandKey={index}
                link={`/${link.element.uri}`}
                selected={expandedKey === index}
                children={link.children}
              />
            ))}
          </ul>

          <nav className={styles.Nav__footerNav}>
            {footerNav.map((navLink, key) => {
              return (
                <button
                  key={key}
                  className={checkCurrentPage(
                    navLink.url,
                    styles.Nav__linkActive
                  )}
                  dangerouslySetInnerHTML={{ __html: navLink.title }}
                  onClick={e =>
                    navAndRemoveActive(e, navLink.url, setActiveClass(false))
                  }
                ></button>
              );
            })}

            <div className={styles.Nav__footerNavSocials}>
              {socialLinksData.map((socialLink, key) => {
                return (
                  <a key={key} href={socialLink.linkUrl}>
                    <img
                      src={socialLink.socialIcon[0].url}
                      alt={socialLink.socialIcon[0].altText}
                    />
                  </a>
                );
              })}
            </div>
          </nav>

          <a className={styles.Nav__newsletter} href={newsLetterSignupLink}>
            <Envelope />
            <span>Sign Up For Our Newsletter</span>
          </a>
        </div>
      </nav>
    </header>
  );
}

Nav.defaultProps = {
  heroVariation: null,
  logoColor: "light"
};

Nav.propTypes = {
  heroVariation: PropTypes.string
};
