import React, { useState, useEffect } from "react";
import { InView } from "react-intersection-observer";
import { graphql, useStaticQuery, Link } from "gatsby";
import styles from "./index.module.css";

export default function WorkCard() {
  const data = useStaticQuery(graphql`
    {
      craft {
        categories {
          ... on Craft_worksCategory_Category {
            title
          }
        }
        entries(type: "work") {
          ... on Craft_works_work_Entry {
            id
            postDate
            title
            showName
            category {
              id
              title
              slug
            }
            heroBackgroundImage {
              url
              crop: url(width: 600, height: 400, position: "centerCenter")
              retina: url(width: 956, height: 600, position: "centerCenter")
              id
            }
            workTilePreviewImage {
              url
              crop: url(width: 600, height: 400, position: "centerCenter")
              retina: url(width: 956, height: 600, position: "centerCenter")
              id
            }
            uri
          }
        }
      }
    }
  `);

  const works = data.craft.entries;
  const categories = [{ title: 'All Work' }, ...data.craft.categories]
  const [filteredWorks, setFilteredWorks] = useState(works);
  const [currentFilter, setCurrentFilter] = useState("All Work");

  // dimensions for lazy loading images
  const height = 400;
  const width = 600;

  useEffect(() => {
    const filterAnchorLink = window.location.hash.replace("#", "");
    if (filterAnchorLink) {
      filterWorks(filterAnchorLink);
    }
  }, []);

  function filterWorks(filter) {
    filter = decodeURIComponent(filter);

    window.location.hash = filter;

    if (document.querySelector("[data-hero-text]")) {
      document.querySelector("[data-hero-text]").innerHTML = filter;
    }

    if (filter === "All Work") {
      setCurrentFilter("All Work");
      return setFilteredWorks(works);
    }

    setCurrentFilter(filter);

    const filtered = works.filter(work => {
      const { category } = work;

      return category.some(item => item.title === filter);
    });

    setFilteredWorks(filtered);
  }

  return (
    <>
      <div className={`${styles.workFilter} ${"container container--1132px"}`}>
        {categories.map(category => (
          <button
            key={category.title}
            className={`${styles.button} ${
              category.title === currentFilter ? styles.buttonActive : ''
            }`}
            onClick={() => filterWorks(category.title)}
          >
            {category.title}
          </button>
        ))}
      </div>

      <ul className="grid list-reset grid3col">
        {filteredWorks.map(entry => {
          const cardImage = entry.workTilePreviewImage.length
            ? entry.workTilePreviewImage
            : entry.heroBackgroundImage;

          return (
            <InView key={entry.id} triggerOnce="true">
              {({ inView, ref }) => (
                <li ref={ref}>
                  <figure className={styles.WorkCard} aria-label="project card">
                    <Link
                      to={`/${entry.uri}/`}
                      style={{
                        position: "relative",
                        paddingBottom: `${(height / width) * 100}%`,
                        background: "#202c36"
                      }}
                    >
                      {cardImage.length > 0 && (
                        <img
                          src={inView ? cardImage[0].crop : ""}
                          srcSet={`
                            ${inView ? cardImage[0].crop + ' 1x': ""},
                            ${inView ? cardImage[0].retina + ' 2x' : ""}
                          `}
                          className={`${
                            inView ? styles["loaded"] : styles[""]
                          }`}
                          width={width}
                          height={height}
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%"
                          }}
                          alt={cardImage[0].altText}
                        />
                      )}

                      <figcaption
                        className={`${styles.WorkCard__summary} antialiased`}
                      >
                        <div>
                          <h3
                            dangerouslySetInnerHTML={{ __html: entry.title }}
                            className={styles.WorkCard__summaryTitle}
                          ></h3>

                          {entry.title.category}

                          <p
                            dangerouslySetInnerHTML={{ __html: entry.showName }}
                            className={styles.WorkCard__summaryData}
                          ></p>
                        </div>
                      </figcaption>
                    </Link>
                  </figure>
                </li>
              )}
            </InView>
          );
        })}
      </ul>
    </>
  );
}

export const workCardData = graphql`
  fragment workCardData on Craft_modules_workCard_BlockType {
    id
    typeHandle
  }
`;
