import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

function SEO({ children }) {
  return (
    <Helmet>
      {children}
      <meta name="facebook-domain-verification" content="zyzrnpnyrs0zhtx5ur39me112u0qar" />
      <link rel="icon" type="image/x-icon" href="/favicon.ico" />
      <link rel="stylesheet" href="https://use.typekit.net/cmz6rxt.css" />
      <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.3/picturefill.min.js"></script>
    </Helmet>
  );
}

SEO.propTypes = {
  children: PropTypes.node
};

export default SEO;
