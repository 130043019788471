import React, { useState } from "react";
import Close from "../../images/close.svg";
import styles from "./index.module.css";

export default function Modal({
  contentNode = <p>I am content!</p>,
  showModal = false
}) {
  const [isModalShowing, setModalShowing] = useState(true);

  if (isModalShowing && showModal) {
    return (
      <section className={styles.Modal} onClick={() => setModalShowing(false)}>
        <div className={styles.Modal__contentWrapper}>
          <span
            onClick={() => setModalShowing(false)}
            className={`${styles.Modal__closeButton}`}
          >
            <Close />
          </span>
          {contentNode}
        </div>
      </section>
    );
  }

  return null;
}
