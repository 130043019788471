import React from "react";
import { graphql } from "gatsby";
import styles from "./index.module.css";
import VideoPlayer from "../VideoPlayer";

export default function FullwidthImage({ image }) {

  return (
    <div
      className={`${styles.FullwidthImage} ${"container container--1132px"}`}
    >
      {image[0].url.includes(".mp4") ? (
        <VideoPlayer src={encodeURI(image[0].url)} />
      ) : (
        <picture>
          <source
            media="(min-width: 1024px)"
            srcSet={` ${image[0].xLarge + ' 1x'}, ${image[0].xLarge_2x + ' 2x'} `}
          />
          <source
            media="(min-width: 700px)"
            srcSet={` ${image[0].large + ' 1x'}, ${image[0].large_2x + ' 2x'} `}
          />
          <source
            media="(min-width: 500px)"
            srcSet={` ${image[0].medium + ' 1x'}, ${image[0].medium_2x + ' 2x'} `}
          />
          <img
            src={encodeURI(image[0].small)}
            srcSet={` ${image[0].small + ' 1x'}, ${image[0].small_2x + ' 2x'} `}
            alt={image[0].altText}
          />
        </picture>
      )}
    </div>
  );
}

export const fullwidthImageData = graphql`
  fragment fullwidthImageData on Craft_modules_fullwidthImage_BlockType {
    id
    typeHandle
    image {
      ... on Craft_uploads_Asset {
        id
        url
        xLarge: url(width: 1600, height: 500, position: "centerCenter")
        large: url(width: 1024, height: 500, position: "centerCenter")
        medium: url(width: 700, height: 500, position: "centerCenter")
        small: url(width: 500, height: 500, position: "centerCenter")
        xLarge_2x: url(width: 3200, height: 1000, position: "centerCenter")
        large_2x: url(width: 2052, height: 1000, position: "centerCenter")
        medium_2x: url(width: 1400, height: 1000, position: "centerCenter")
        small_2x: url(width: 1000, height: 1000, position: "centerCenter")
        altText
      }
    }
  }
`;
