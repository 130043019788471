import React from "react";
import { graphql } from "gatsby";
import styles from "./index.module.css";

export default function TextBlock(props) {
  // Insert CSS for image styling that comes directly from Craft
  // Declared outside of the CSS Modules because the classes aren't going
  // to be able to be CSS Module-ified
  const css = `
    .TextBlock__image-full-width img {
      width: 100%;
    }

    .TextBlock__image-auto-width img {
      width: auto;
    }
  `;

  const darkStyle = props.showDarkVariant === true ? styles.TextBlock__dark : '';

  return (
    <section id="text-block" className={[styles.TextBlock, darkStyle].join(' ')}>
      <div
        className={"container container--1132px "}
        dangerouslySetInnerHTML={{ __html: props.richText }}
      ></div>
      <style>{css}</style>
    </section>
  );
}

export const textBlockData = graphql`
  fragment textBlockData on Craft_modules_textBlock_BlockType {
    typeHandle
    id
    enabled
    richText
    showDarkVariant
  }
`;
