import React from "react";
import { Link } from "gatsby";
import styles from "./index.module.css";
import Arrow from "../../images/arrow.svg";
import PropTypes from "prop-types";

export default function CtaCard({
  image,
  overlay,
  ctaHeading,
  ctaText,
  internalButtonLink,
  externalButtonLink,
  buttonText,
  newTab,
  showDarkVariant,
}) {
  const mod = overlay ? "Overlay" : "Default"; // [Overlay, Default] lets make no overlay default, it plays nicely with the lightswitch setup
  const buttonType = overlay ? "secondary" : "primary"; // Using overlay to determine button color
  return (
    <div
      className={`${styles.CtaCard} ${styles[`CtaCard` + mod]}`}
    >
      {image[0] && (
        <picture className={` ${showDarkVariant ? styles.CtaCard__darkImage : ""} `}>
          <source
            media="(min-width: 1024px)"
            srcSet={` ${image[0].xLarge + ' 1x'}, ${image[0].xLarge_2x + ' 2x'} `}
          />
          <source
            media="(min-width: 700px)"
            srcSet={` ${image[0].large + ' 1x'}, ${image[0].large_2x + ' 2x'} `}
          />
          <source
            media="(min-width: 500px)"
            srcSet={` ${image[0].medium + ' 1x'}, ${image[0].medium_2x + ' 2x'} `}
          />
          <img
            src={encodeURI(image[0].small)}
            srcSet={` ${image[0].small + ' 1x'}, ${image[0].small_2x + ' 2x'} `}
            alt={image[0].altText}
          />
        </picture>
      )}
      <div className={`${styles[`CtaCard__vcenter`]} container container--1132px`}>
        <div
          className={`${mod === "Overlay" ? "" : styles.CtaCard__Stripe} ${
            styles.CtaCard__Wrap
          }`}
        >
          <h3 className={`${styles.CtaCard__Title}`}>{ctaHeading}</h3>
          <p>{ctaText}</p>
          {externalButtonLink && (
            <a
              target={newTab ? '_blank' : '_self'}
              href={externalButtonLink}
              className={`button button--icon button--${buttonType}`}
              role="button"
            >
              {buttonText}
              <Arrow />
            </a>
          )}
          {internalButtonLink[0] && (
            <Link to={`/${internalButtonLink[0].uri}/`}>
              <button className={`button button--icon button--${buttonType}`}>
                {buttonText}
                <Arrow />
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

CtaCard.propTypes = {
  image: PropTypes.array,
  overlay: PropTypes.bool,
  ctaHeading: PropTypes.string,
  ctaText: PropTypes.string,
  internalButtonLink: PropTypes.array,
  externalButtonLink: PropTypes.string,
  buttonText: PropTypes.string,
  newTab: PropTypes.bool,
};

CtaCard.defaultProps = {
  image: [{
    xLarge: "https://source.unsplash.com/random/1600x526",
    large: "https://source.unsplash.com/random/1024x526",
    medium: "https://source.unsplash.com/random/700x526",
    small: "https://source.unsplash.com/random/500x526",
    xLarge_2x: "https://source.unsplash.com/random/3200x1052",
    large_2x:"https://source.unsplash.com/random/2052x1052",
    medium_2x: "https://source.unsplash.com/random/1400x1052",
    small_2x:"https://source.unsplash.com/random/1000x1052"
  }],
  overlay: false,
  ctaHeading: "Default CTA Heading",
  ctaText:
    "Erat ac libero porta semper senectus proin aenean inceptos sollicitudin in dictumst parturient duis rutrum massa placerat lorem mi porttitor.",
  internalButtonLink: [{ uri: "/" }],
  externalButtonLink: null,
  buttonText: "Default Button Text",
  newTab: false,
};

export const ctaCardData = graphql`
  fragment ctaCardData on Craft_modules_ctaCard_BlockType {
    id
    typeHandle
    showDarkVariant
    image {
      ... on Craft_uploads_Asset {
        id
        xLarge: url(width: 1600, height: 526, position: "centerCenter")
        large: url(width: 1024, height: 526, position: "centerCenter")
        medium: url(width: 700, height: 526, position: "centerCenter")
        small: url(width: 500, height: 526, position: "centerCenter")
        xLarge_2x: url(width: 3200, height: 1052, position: "centerCenter")
        large_2x: url(width: 2052, height: 1052, position: "centerCenter")
        medium_2x: url(width: 1400, height: 1052, position: "centerCenter")
        small_2x: url(width: 1000, height: 1052, position: "centerCenter")
        altText
      }
    }
    overlay
    ctaHeading
    ctaText
    externalButtonLink
    internalButtonLink {
      uri
    }
    buttonText
    newTab
  }
`;
