import React, { useState } from "react";
import { useInView } from 'react-intersection-observer';
import styles from "./index.module.css";

export default function TeamMemberCard(props) {
  const { image, title, bio } = props;

  const [ref, inView] = useInView({
    triggerOnce: true,
    root: null,
    rootMargin: "0px",
    threshold: 0
  });

  const [isExpanded, setExpanded] = useState(false);

  // dimensions for lazy loading images
  const height = 515;
  const width = 637;

  return (
    <li>
      <figure
        className={styles.TeamMember}
        aria-label="team member"
        ref={ref}
        style={{
          position: 'relative',
          paddingBottom: `${(height / width) * 100}%`,
          background: '#202c36'
        }}>
        {image[0] && (
          <img
            src={inView ? image[0].crop : ""}
            srcSet={`
              ${inView ? image[0].crop + ' 1x': ""},
              ${inView ? image[0].retina + ' 2x': ""}
            `}
            width={width}
            height={height}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%'
            }}
            className={`${inView ? styles["loaded"] : styles[""]}`}
            alt={image[0].altText}
          />
        )}

        <figcaption>
          <button
            onClick={() => setExpanded(!isExpanded)}
            aria-label="click to read team member bio"
          >
            {title}
            <b aria-hidden="true" tabIndex="-1">
              {isExpanded ? "-" : "+"}
            </b>
          </button>

          <div
            className={isExpanded ? "" : "hidden"}
            tabIndex={isExpanded ? "0" : "-1"}
            aria-hidden={isExpanded ? "false" : "true"}
            aria-expanded={isExpanded ? "true" : "false"}
            aria-label={bio}
          >
            <p className={styles.TeamMember__bio}>{bio}</p>
          </div>
        </figcaption>
      </figure>
    </li>
  );
}
