import React, { useState } from "react";
import styles from "./index.module.css";
import { checkCurrentPage, navAndRemoveActive } from "../Helpers";
import useViewportSizes from "use-viewport-sizes";

export default function NavLink(props) {
  // Props
  const { title, link, children, keyExapanded, expandKey, selected } = props;
  const hasChildren = children.length > 0;

  const [vpWidth] = useViewportSizes();

  // Functions
  const toggleDesktopChildren = () =>
    vpWidth >= 960 ? () => keyExapanded(expandKey) : null;

  const toggleMobileChildren = e => {
    e.stopPropagation();

    if (vpWidth <= 960) {
      if (selected) {
        keyExapanded(false)
      } else {
        keyExapanded(expandKey);
      }
    }
  };

  return (
    <li
      onMouseEnter={toggleDesktopChildren()}
      onMouseLeave={toggleDesktopChildren()}
      className={`${checkCurrentPage(link, styles.Nav__linkActive)} ${
        hasChildren ? styles.Nav__subMenu : null
      }`}
    >
      <button
        onClick={e => navAndRemoveActive(e, link)}
        className={styles.Nav__link}
      >
        <span dangerouslySetInnerHTML={{ __html: title }}></span>
        {hasChildren && (
          <span
            onClick={e => toggleMobileChildren(e)}
            className={styles.Nav__arrow}
          >
            ▼
          </span>
        )}
      </button>

      {selected && (
        <ul className="list-reset">
          {children.map((childLink, index) => {
            if (childLink.slug === null) {
              return (
                <li key={index}>
                  <a href={childLink.url} target={childLink.newWindow === '1' ? '_blank' : ''} >
                    {childLink.title}
                  </a>
                </li>
              )
            } else {
              return (
                <li key={index}>
                  <button
                    dangerouslySetInnerHTML={{ __html: childLink.title }}
                    onClick={e => navAndRemoveActive(e, childLink.url)}
                  ></button>
                </li>
              );
            }
          })}
        </ul>
      )}
    </li>
  );
}
