import { navigate } from "gatsby";

export const checkCurrentPage = (url, classes) => {
  if (typeof window !== "undefined") {
    const path = window.location.pathname;
    if (url.startsWith("http")) {
      const fullURL = new URL(url);
      return path.includes(fullURL.pathname) ? classes : null;
    }

    return path.includes(url) ? classes : null;
  }
};

export function navAndRemoveActive(e, url, fn = null) {
  e.stopPropagation();
  document.querySelector("html").classList.remove("active");
  if (fn !== null) {
    fn();
  }
  if (url.startsWith("http")) {
    const fullURL = new URL(url);
    return navigate(`${fullURL.pathname}/`);
  }
  return navigate(`${url}/`);
}

export function getUrlParams(search) {
  let hashes = search.slice(search.indexOf('?') + 1).split('&')
  return hashes.reduce((params, hash) => {
      let [key, val] = hash.split('=')
      return Object.assign(params, {[key]: decodeURIComponent(val)})
  }, {})
}
